import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

const Header = ({ siteTitle }) => (
  <header>
    <div>
      <div className="box">
        <h1>{siteTitle}</h1>
        <p>Software Dude</p>
      </div>
      <nav>
        <Link to="/" className="internal">
          About
        </Link>
        <a href="http://art.pprice.me/" className="internal">
          Art
        </a>
        <span className="mobileBreak"></span>
        <a href="//www.linkedin.com/in/phil-price-816b745/" className="social">
          <i className="fab fa-linkedin"></i>
        </a>
        <a href="//github.com/pprice" className="social">
          <i className="fab fa-github"></i>
        </a>
        <a href="//www.pinterest.com/mrphilprice/" className="social">
          <i className="fab fa-pinterest"></i>
        </a>
      </nav>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
